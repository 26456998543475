<script>
import GlobalVue from '@helper/Global.vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';

export default {
	extends: GlobalVue,
	data() {
		return {
			input:{},
			wa:{}
		}
	},
	async mounted() {
		this.getWa()
	},
	computed:{
		yearcopy(){
			let year = 2021
			let cyear = (new Date).getFullYear()
			return year == cyear ? year : year+" - "+cyear
		}
	},
	methods: {
		serachkeyword(){
			if(!this.input.q) return
			$('.mfp-close').click()
			this.$router.push({
                path: '/search-result',
                query: { q: this.input.q }
            })
            this.input={}
		},
		getWa(){
			Gen.apirest("/wa", {}, (err, resp) => {
                if (err) console.log(err)
                this.wa = resp
            })
		}
	}

};
</script>
<template>
	<div class="m-0 bg-inherit bg_blue_general" v-if="$route.name=='HealthInfoCat' || $route.name=='HealthInfo' || $route.name=='NewsEvent'">
		<footer id="footer" class="dark">
			<div class="footer-bg"></div>
			<!-- Copyrights
					============================================= -->
			<div id="copyrights">
				<div class="container clearfix">
					<div class="row">
						<div class="col-md-6">
							<span class="text-white">{{web.copy_text+' &copy; '+yearcopy+' '+web.all_reserved}}</span>
						</div>
						<div class="col-md-6">
							<div class="terms-links">
								<router-link :to="{name:'Toc'}">{{web.menu_terms_conditions}}</router-link>
								<router-link :to="{name:'Privacy'}">{{web.menu_privacy_policy}}</router-link>
							</div>
						</div>
					</div>
				</div>
			</div><!-- #copyrights end -->

			<div id="searchBox" class="mfp-hide">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-md-8 col-lg-6">
							<div class="form-group">
								<input type="text" class="form-control" v-model="input.q" placeholder="e. g. health info, news, event, product" v-on:keyup.enter="serachkeyword">
							</div>
							<div class="form-group text-center text-md-left">
								<button @click="serachkeyword()" class="cta cta-primary cta--reveal"><span>Cari</span><i class="icon-line-arrow-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>

			
	        <div id="gotoTop" class="icon-angle-up"></div>
	        <a href="https://wa.me/6281119002297" target="_blank" id="waContact"><i class="icon-whatsapp"></i></a>
		</footer>
	</div>
	<footer v-else id="footer" class="dark">
			<div class="footer-bg"></div>
			<!-- Copyrights
					============================================= -->
			<div id="copyrights">
				<div class="container clearfix">
					<div class="row">
						<div class="col-md-6">
							<span class="text-white">{{web.copy_text+' &copy; '+web.nutrifood+' '+yearcopy+' '+web.all_reserved}}</span>
						</div>
						<div class="col-md-6">
							<div class="terms-links">
								<router-link :to="{name:'Toc'}">{{web.menu_terms_conditions}}</router-link>
								<router-link :to="{name:'Privacy'}">{{web.menu_privacy_policy}}</router-link>
							</div>
						</div>
					</div>
				</div>
			</div><!-- #copyrights end -->

			<div id="searchBox" class="mfp-hide">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-md-8 col-lg-6">
							<div class="form-group">
								<input type="text" class="form-control" v-model="input.q" placeholder="e. g. health info, news, event, product" v-on:keyup.enter="serachkeyword">
							</div>
							<div class="form-group text-center text-md-left">
								<button @click="serachkeyword()"  class="cta cta-primary cta--reveal"><span>Cari</span><i class="icon-line-arrow-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>
	        <a :href="'https://wa.me/'+wa.as_val" target="_blank" id="waContact"><i class="icon-whatsapp"></i></a>
		</footer>
</template>